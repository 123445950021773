/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'notification': {
    width: 15,
    height: 18,
    viewBox: '0 0 15 18',
    data: '<path pid="0" d="M11.47 13.499H3.53v-5.85c0-2.25 1.764-4.05 3.97-4.05 2.206 0 3.97 1.8 3.97 4.05v5.85zm1.765-.9v-4.95c0-2.763-1.888-5.076-4.411-5.688v-.612c0-.358-.14-.701-.388-.955A1.31 1.31 0 007.5 0a1.31 1.31 0 00-.936.395 1.364 1.364 0 00-.388.955v.612c-2.532.612-4.411 2.925-4.411 5.688v4.95L0 14.399v.9h15v-.9l-1.765-1.8zm-5.735 5.4c.468 0 .917-.19 1.248-.527.33-.338.517-.796.517-1.273h-3.53c0 .477.186.935.517 1.273.331.337.78.527 1.248.527z" _fill="#898B9B"/>'
  }
})
